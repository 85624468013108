import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../../../styles/global'

import { motion, AnimatePresence } from 'framer-motion'

const BlockFaqItem = (props) => {
    const [expanded, setExpanded] = useState(false)
    const {
        question,
        answer_heading,
        answer_text,
        answer_link_display,
        answer_link_text,
        answer_link,
    } = props

    const variants = {
        initial: {
            height: 0,
        },
        animate: {
            height: 'auto',
            transition: {
                ease: [0.4, 0, 0.2, 1],
                duration: 0.6,
            },
        },
        exit: {
            height: 0,
            transition: {
                duration: 0.1,
                ease: [0.4, 0, 0.2, 1],
            },
        },
    }

    return (
        <Container active={expanded}>
            <Header onClick={() => setExpanded(!expanded)}>
                <Title>{question}</Title>
                <Icon />
            </Header>

            <AnimatePresence initial={false}>
                {expanded && (
                    <Section {...variants}>
                        {answer_heading && <Heading>{answer_heading}</Heading>}

                        <Text
                            dangerouslySetInnerHTML={{ __html: answer_text }}
                        />

                        {answer_link_display && (
                            <Button
                                as={'a'}
                                href={answer_link}
                                target="_target"
                            >
                                {answer_link_text}
                            </Button>
                        )}
                    </Section>
                )}
            </AnimatePresence>
        </Container>
    )
}

// Shared

const Header = styled.div``
const Section = styled(motion.div)``
const Title = styled.div``
const Icon = styled.div``
const Heading = styled.div``
const Text = styled.div``
const Button = styled.div``

// Wrapper

BlockFaqItem.wrapper = css`
    margin: 0;
`

// Layout

const Container = styled.div`
    background: ${colours.lightBeige};
    border-radius: 22px;
    margin-bottom: 12px;
    padding: 28px 28px 22px 60px;

    width: 100%;
    max-width: 1020px;
    transition: 0.6s cubic-bezier(0.4, 0, 0.2, 1);

    ${media.tablet`
		padding: 20px;	
	`}

    &:hover {
        background: ${colours.lightBlue};
        cursor: pointer;
    }

    ${(props) => {
        if (props.active == true)
            return css`
                background: ${colours.lightBlue};
            `
    }}

    ${Header} {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${Title} {
            font-size: 30px;
            font-family: 'Souvenir';

            ${media.tablet`
				font-size: 20px;
			`}
        }

        ${Icon} {
            background-image: url(${require('../../../assets/svg/icon-chevron-circle.svg')});
            width: 58px;
            height: 58px;
            ${bgIcon}
            transition: 0.6s cubic-bezier(0.4, 0, 0.2, 1);

            ${(props) => {
                if (props.active == true)
                    return css`
                        transform: rotate(180deg);
                    `
            }}

            ${media.tablet`
				width: 40px;
				height: 40px;
				min-width: 40px;
			`}
        }
    }

    ${Section} {
        padding: 20px 0;
        max-width: 80%;
        overflow: hidden;

        ${media.tablet`
			max-width: 100%;
			padding: 20px 0 0;
		`}

        ${Heading} {
            font-size: 24px;
            padding-bottom: 20px;
        }

        ${Text} {
            p,
            ul,
            li {
                font-size: 18px;

                a {
                    text-decoration: underline;
                    font-size: inherit;
                    color: inherit;
                }
            }

            a {
                text-decoration: underline;
                font-size: inherit;
                color: inherit;
            }

            ul {
                margin: 0;
                padding-left: 1.3rem;
            }
        }

        ${Button} {
            ${button};
            color: ${colours.black};
        }
    }
`

export default BlockFaqItem
