import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const BlockTextImage = (props) => {
	const { heading, text, image_position, image } = props;
        
	return (
		<>
			<Container align={image_position}>
				<Wrap>
					{heading && <Title>{heading}</Title>}
					<Text dangerouslySetInnerHTML={{ __html: text }} />
				</Wrap>
				<Wrap>
					{image && (
						<Image
							key={image?.sizes?.medium2}
							src={image?.sizes?.medium2}
						>
							{(src, loading) => {
								return (
									<BGImage
										image={src}
										style={{ opacity: loading ? 0 : 1 }}
									/>
								)
							}}
						</Image>
					)}
				</Wrap>
			</Container>
		</>
	)
}

// Shared


const Wrap = styled.div``
const Title = styled.div``
const SubHeading = styled.div``
const Text = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

//Wrapper

BlockTextImage.wrapper = css``

// Layout

const Container = styled.div`
	display: flex;
	justify-content: space-between;

	${props => {
		if (props.align == 'left') return css`
			flex-direction: row-reverse;
		`
	}}
	
	${media.tablet`
		flex-direction: column;
	`}

	${Wrap} {
		flex-basis: calc(50% - 20px);

		${Title} {
			${type.heading1};
			padding-bottom: 32px;

			${media.tablet`
				padding-bottom: 20px;	
			`}
		}

		${SubHeading} {
			${type.body};
			padding-bottom: 32px;
		}

		${Text} {
			${type.body};
			// font-size: 21px;

			// p {
			// 	font-size: 21px;
			// 	margin-bottom: 50px;
			// }

			// ul {
			// 	margin: 0;
			// 	padding: 0;
			// }

			// li {
			// 	padding-left: 44px;
			// 	margin-bottom: 30px;
			// 	list-style-type: none;
			// 	position: relative;

			// 	&:before {
			// 		content: " ";
			// 		position: absolute;
			// 		top: 8px;
			// 		left: 0;
			// 		width: 18px;
			// 		height: 18px;
			// 		background: black;
			// 		border-radius: 50%;
			// 	}
			// }
		}
		${BGImage} {
            ${bgIcon};
            height: 100%;

            ${media.tablet`
                min-height: 50vw;
			`}
        }
	}
`

export default BlockTextImage
