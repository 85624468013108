import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../../../styles/global'

const Section = (props) => {
    const { classes, title, text, link, linkText } = props

    // Render List

    const renderList = () => {
        return classes.map((item, i) => {
            return (
                <ListItem key={i}>
                    {item.image && (
                        <Image
                            key={item.image?.sizes?.full_width}
                            src={item.image?.sizes?.full_width}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                    <Title>{item.title}</Title>
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item?.short_description ? item.short_description : item.description,
                        }}
                    />

                    {item.link && item.link.type == 'external' && (
                        <Button as={'a'} href={item.link.url} target="_blank">
                            {item.link.title}
                        </Button>
                    )}

                    {item.link && item.link.type == 'internal' && (
                        <Button as={Link} to={item.link.url}>
                            {item.link.title}
                        </Button>
                    )}
                </ListItem>
            )
        })
    }

    return (
        <Container>
            <Meta>
                <Heading dangerouslySetInnerHTML={{ __html: title }} />
                <Text dangerouslySetInnerHTML={{ __html: text }} />

                {link && (
                    <Button as={Link} to={link}>
                        {linkText}
                    </Button>
                )}
            </Meta>

            {renderList()}
        </Container>
    )
}

// Shared

const Meta = styled.div``
const Heading = styled.div``
const Title = styled.div``
const Text = styled.div``
const Description = styled.div``
const Button = styled.div``
const ListItem = styled.div``

// Utility

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgIcon};
    transition: opacity 0.45s ease;
`

// Wrapper

Section.wrapper = css`
    ${media.tablet`
		margin-bottom: 0px;	
	`}
`

// Layout

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${colours.lightOrange};
    padding: 80px 40px;
    border-radius: 31px;

    ${media.tablet`
		flex-wrap: wrap;
		padding: 32px 20px;
		margin-bottom: 40px;
	`}

    ${Meta} {
        width: calc(33.33% - 20px);
        display: flex;
        flex-direction: column;

        ${media.tablet`
			width: 100%;
			padding-bottom: 40px;	
		`}

        ${Heading} {
            ${type.heading1};
            padding-bottom: 34px;
        }

        ${Text} {
            max-width: 80%;

            ${media.tablet`
				max-width: 100%;	
			`}

            p {
                font-size: 24px;

                ${media.tablet`
					font-size: 18px;	
				`}
            }

            a {
                border-bottom: 1px solid;
                padding-bottom: 2px;
                color: ${colours.black};
                display: inline-block;
                width: fit-content;
            }
        }

        ${Button} {
            font-size: 21px;
            color: ${colours.black};
            border-bottom: 1px solid;
            padding-bottom: 2px;
            display: inline-block;
            width: fit-content;

            ${media.tablet`
				font-size: 18px;	
			`}

            &::before {
                content: '→';
                display: inline-block;
                color: ${colours.black};
                margin-right: 12px;
            }
        }
    }

    ${ListItem} {
        width: calc(33.33% - 20px);

        ${media.tablet`
			width: calc(50% - 12px);	
		`}

        ${BGImage} {
            width: 176px;
            height: 149px;
            margin-bottom: 42px;

            ${media.tablet`
				width: 86px;
				height: 64px;
				margin-bottom: 20px;
			`}
        }

        ${Title} {
            font-size: 24px;
            padding-bottom: 30px;

            ${media.tablet`
				font-size: 18px;	
			`}
        }

        ${Description} {
            p {
                font-size: 21px;
                margin: 0 0 12px;

                a {
                    font-size: inherit;
                    border-bottom: 1px solid;
                    padding-bottom: 2px;
                    color: ${colours.black};
                    display: inline-block;

                    ${media.tablet`
						font-size: 15px;	
					`}
                }

                ${media.tablet`
					font-size: 15px;	
				`}
            }
        }

        ${Button} {
            font-size: 21px;
            color: ${colours.black};
            border-bottom: 1px solid;
            padding-bottom: 2px;
            display: inline-block;

            ${media.tablet`
				font-size: 18px;	
			`}

            &::before {
                content: '→';
                display: inline-block;
                color: ${colours.black};
                margin-right: 12px;
            }
        }
    }
`

export default Section
