import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const BlockHeading = (props) => {
	const { content } = props;
        
	return (
		<Title class="block-heading">{content}</Title>
	)
}


// Wrapper

BlockHeading.wrapper = css`
	margin: 0;
`

// Layout

const Title = styled.div`
	${type.heading1};
	padding-bottom: 32px;
`

export default BlockHeading
