import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, colours, type } from '../../../styles/global'

const BlockLocation = (props) => {
    const { slug, title, description, address } = props;

    const buttonCaption = slug.includes('lyall-bay') ? 'Learn More' : 'Book Now';
    const buttonHref = slug.includes('lyall-bay') ? '/locations/' + slug : '/locations/' + slug + '#timetable';

    return (
        <Location className={'location_card'} as={'a'} href={'/locations/' + slug}>
            <Wrap className='contents'>
                <SubHeading>{title}</SubHeading>
                <Description>{description}</Description>
            </Wrap>

            <Wrap className='footer'>
                <Button className='button' as={'a'} href={buttonHref}>{buttonCaption}</Button>
                <Text className='address'>{address}</Text>
            </Wrap>
        </Location>
    )
}

// Shared

const Wrap = styled.div``
const SubHeading = styled.div``
const Text = styled.div``
const Description = styled.div``
const Button = styled.div``

const Location = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;

    &:hover {
        background: ${colours.bloodOrange};
        cursor: pointer;

        ${Description}{
            color: ${colours.white};
        }
    }

    ${Wrap}{
        &.contents {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding-bottom: 50px;

            ${media.tablet`
                gap: 12px;
            `}
        }

        &.footer {
            display: flex;
            gap: 16px;
            align-items: center;

            ${media.desktop`
                flex-direction: column;
                align-items: flex-start;
            `}
        }

        ${SubHeading}, ${Button} {
            font-size: 24px;
            line-height: 1.2;
        }

        ${Description}{
            font-size: 24px;
            line-height: 1.2;
            opacity: 0.6;

            ${media.tablet`
                font-size: 18px;
            `}
        }

        ${Button}{
            background: ${colours.black};
            color: ${colours.white};
            padding: 12px 24px;
            border-radius: 50px;
            width: fit-content;

            ${media.tablet`
                font-size: 18px;
            `}
        
            &:hover {
                background: #0000008a;
                cursor: pointer;
            }
        }

        ${Text}{
            font-size: 20px;

            ${media.tablet`
                font-size: 18px;
            `}
        }
    }
`

BlockLocation.wrapper = css`
    margin-bottom: 0;
    flex-grow: 1;
    background: ${colours.lightPurple};
    border-radius: 12px;
    overflow: hidden;
`

export default BlockLocation