import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const Section = (props) => {
	const { title, image, text, useLink, link, linkText } = props;
        
	return (
		<Container>
			<Wrap>
				<Title dangerouslySetInnerHTML={{__html: title}} />
				<Text dangerouslySetInnerHTML={{__html: text}} />

				{useLink && (
					<Button as={Link} to={link}>{linkText}</Button>
				)}
			</Wrap>
			<Wrap>
				{image && (
					<Image
						key={image?.sizes?.full_width}
						src={image?.sizes?.full_width}
					>
						{(src, loading) => {
							return (
								<BGImage
									image={src}
									style={{ opacity: loading ? 0 : 1 }}
								/>
							)
						}}
					</Image>
				)}
			</Wrap>
		</Container>
	)
}

// Shared

const Wrap = styled.div``
const Title = styled.div``
const Text = styled.div``
const Button = styled.div``

// Utility 

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgIcon};
    transition: opacity 0.45s ease;
`

// Wrapper

Section.wrapper = css``

// Layout

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 180px 150px;
	box-sizing: border-box;

	${media.tablet`
		padding: 30px 0;
		flex-direction: column;
	`}

	${Wrap} {
		flex-basis: 50%;

		${media.tablet`
			flex-basis: 100%;
		`}

		${Title} {
			${type.heading2};
			color: ${colours.black};
			padding-bottom: 28px;
		}

		${Text} {
			${type.body};
			color: ${colours.black};
			padding-bottom: 20px;
		}

		${Button} {
			${button};
			color: ${colours.black};
			border-color: ${colours.orange};
			
			${media.tablet`
				border-color: ${colours.orange};	
			`}

			&:hover {
				background: ${colours.orange};
			}
		}

		${BGImage} {
			width: 100%;
			min-height: 420px;

			${media.tablet`
				display: none;	
			`}
		}
	}
`

export default Section
