import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../../../styles/global'

const Section = (props) => {
    const { classTypes, title, text, image } = props

    const renderList = () => {
        const items = classTypes.map((item, i) => {
            return (
                <ListItem
                    as={Link}
                    to={'/what-we-offer/#' + item.type.slug}
                    key={i}
                >
                    <Icon />
                    <Wrap>
                        <Title>{item.type.name}</Title>
                        <Description>{item.short_description}</Description>
                    </Wrap>
                </ListItem>
            )
        })

        return <List>{items}</List>
    }

    return (
        <Container>
            <Title>{title}</Title>
            <Wrap>
                <Meta>
                    {text && (
                        <Text dangerouslySetInnerHTML={{ __html: text }} />
                    )}

                    {renderList()}

                    <Button as={Link} to="/what-we-offer">
                        All Classes
                    </Button>
                </Meta>

                <Image
                    key={image?.sizes?.full_width}
                    src={image?.sizes?.full_width}
                >
                    {(src, loading) => {
                        return (
                            <BGImage
                                image={src}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            </Wrap>
        </Container>
    )
}

// Shared

const Title = styled.div``
const Text = styled.div``
const Wrap = styled.div``
const Meta = styled.div``
const List = styled.div``
const ListItem = styled.div``
const Icon = styled.div``
const Description = styled.div``
const Button = styled.div``

// Utility

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgIcon};
    transition: opacity 0.45s ease;
`

// Wrapper

Section.wrapper = css``

// Layout

const Container = styled.div`
	margin-bottom: 150px;

	${media.tablet`
		margin-bottom: 40px;	
	`}

	${Title} {
		${type.heading1};
		padding-bottom: 44px;

		${media.tablet`
			padding-bottom: 20px;
		`}
	}

	${Wrap} {
		display: flex;
		justify-content: space-between;

		${media.tablet`
			flex-direction: column-reverse;
			justify-content: flex-start;
		`}

		${Meta}, ${Image} {
			flex-basis: 50%;

			${media.tablet`
				flex-basis: 100%;
			`}
		}

		${Meta} {
			${media.tablet`
				padding-top: 20px;	
			`}

			${List} {
				display: flex;
				flex-direction: column;
				padding-right: 80px;

				${media.tablet`
					padding-right: 0;
				`}

				${ListItem}{
					border-bottom: 3px solid ${colours.black};
					padding: 30px 0;
					box-sizing: border-box;

					display: flex;
					align-items: center;

					${media.tablet`
						padding: 20px 0;
						border-bottom: 2px solid ${colours.black};
					`}

					&:last-child {
						border: 0;
					}

					${Icon} {
						width: 67px;
						height: 58px;
						background: white;
						border-radius: 50%;
						margin-right: 35px;

						${media.tablet`
							margin-right: 16px;
							width: 57px;
							height: 46px;
						`}
					}

					${Wrap} {
						color: ${colours.black};
						width: 100%;
						display: flex;
						flex-direction: column;	

						${Title} {
							${type.body};
							padding: 0;
							padding-bottom: 4px;
						}

						${Description} {
							font-size: 24px;

							${media.tablet`
								font-size: 18px;
							`}
						}
					}
				}
			}

			${Button} {
				margin-top: 50px;
				${button};
				color: ${colours.black};
				border-color: ${colours.purple};

				${media.tablet`
					margin-top: 20px;
					border-color: ${colours.purple};
				`}
			}
		}

		${BGImage} {
			flex-basis: 50%;

			${media.tablet`
				flex-basis: 100%;
				min-height: 250px;
			`}
		}
	}
`

export default Section
